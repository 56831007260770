import {useMessageService} from "../../../../hooks/useMessageService";
import React, {useEffect, useState} from "react";
import {InterconnectApplicantStep} from "../../helpers/InterconnectApplicantStepIcons";
import {FormProvider, useForm, useWatch} from "react-hook-form";
import {Form, Label} from "../../../../styledcomponents/FormStyledComponents";
import GreyBanner from "../../../misc/GreyBanner";
import {Divider, GridColumnContainer} from "../../../../styledcomponents/MiscStyledComponents";
import {
    Input,
    ServerLoadedSelect,
    TextArea
} from "../../../form/FormElements";
import {Pattern} from "../../../../enums/Pattern";
import {InterconnectApplicantButtons} from "./InterconnectApplicantButtons";
import {
    ConnectionInformationFormData
} from "./types/InterconnectApplicantTypes";
import {InterconnectApplicantStepProps} from "./InterconnectApplicantForm";

type ConnectionInformationProps = InterconnectApplicantStepProps & {
}

export default function ConnectionInformation(props : ConnectionInformationProps) {
    const messageService = useMessageService();

    // Form state handlers
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

    const methods = useForm<ConnectionInformationFormData>({
        defaultValues : {...props.interconnectApplicantForm?.connectionInformation},
        mode: "onChange"
    });

    const {getValues, control, formState : {isValid, isSubmitting, isDirty, errors}} = methods;
    let watchConnectionInformation = useWatch({control : control});

    function updateConnectionInformation() {
        props.setInterconnectApplicantForm({
            ...props.interconnectApplicantForm!,
            connectionInformation : getValues()
        })
    }

    // useEffects
    useEffect(() => {
        setSubmitDisabled(isSubmitting || !isValid);
    }, [isSubmitting, isValid])

    // Whenever the user modifies the form fields, we want to wait 500 milliseconds for them to stop typing and then
    // update the connection information
    useEffect(() => {
        if (isDirty) {
            const debounceTimer = setTimeout(() => {
                updateConnectionInformation()
            }, 500);

            return () => clearTimeout(debounceTimer);
        }
    }, [watchConnectionInformation, isDirty]);

    function onNext() {
        // clear any messages once we submit
        messageService.clearAll();

        updateConnectionInformation()
    }

    return (
        <FormProvider {...methods}>
            <Form>
                <p>
                    Provide information that describes the connection for the service address
                </p>
                <GreyBanner title={"Additional Information"} />
                <GridColumnContainer columns={2}>
                    <div>
                        <Label htmlFor={"propertyType.identifier"} >Property Type</Label>
                        <ServerLoadedSelect name={"propertyType.identifier"}
                                            url={`/api/dropdown/unrestricted/premiseType`}
                                            required={true}
                                            error={errors?.propertyType?.identifier}
                        />
                    </div>
                    <div>
                        <Label htmlFor={"squareFootage"} >Square Footage</Label>
                        <Input name={'squareFootage'} required={true} maxLength={5} pattern={Pattern.SquareFootage} error={errors?.squareFootage}/>
                    </div>
                </GridColumnContainer>
                <GridColumnContainer columns={1}>
                    <div>
                        <Label htmlFor={"directions"} >Directions</Label>
                        <Input name={'directions'} maxLength={100} required={true} error={errors?.directions}/>
                    </div>
                </GridColumnContainer>

                <GreyBanner title={"Connection Information"} />
                <GridColumnContainer columns={3}>
                    <div>
                        <Label htmlFor={"crossStreet"} >Cross Street</Label>
                        <Input name={'crossStreet'} maxLength={40} required={true} error={errors?.crossStreet}/>
                    </div>
                    <div>
                        <Label htmlFor={"connectionType.identifier"} >Connection Type</Label>
                        <ServerLoadedSelect name={"connectionType.identifier"}
                                            url={`/api/dropdown/unrestricted/connectionType`}
                                            required={true}
                                            error={errors?.connectionType?.identifier}
                        />
                    </div>
                    <div>
                        <Label htmlFor={"setback"} >Setback (In Feet)</Label>
                        <Input name={'setback'} maxLength={5} required={true} pattern={Pattern.Setback} error={errors?.setback}/>
                    </div>
                </GridColumnContainer>
                <GridColumnContainer columns={3}>
                    <div>
                        <Label htmlFor={"voltage.identifier"} >Voltage</Label>
                        <ServerLoadedSelect name={"voltage.identifier"}
                                            url={`/api/dropdown/unrestricted/voltage`}
                                            required={true}
                                            error={errors?.voltage?.identifier}
                        />
                    </div>
                    <div>
                        <Label htmlFor={"amps.identifier"} >Amps</Label>
                        <ServerLoadedSelect name={"amps.identifier"}
                                            url={`/api/dropdown/unrestricted/amps`}
                                            required={true}
                                            error={errors?.amps?.identifier}
                        />
                    </div>
                    <div>
                        <Label htmlFor={"phase.identifier"} >Phase</Label>
                        <ServerLoadedSelect name={"phase.identifier"}
                                            url={`/api/dropdown/unrestricted/phases`}
                                            required={true}
                                            error={errors?.phase?.identifier}
                        />
                    </div>
                </GridColumnContainer>
                <GridColumnContainer columns={1}>
                    <div>
                        <Label htmlFor={"remarks"} >Remarks</Label>
                        <TextArea maxLength={500} name={"remarks"} required={true} error={errors?.remarks}/>
                    </div>
                </GridColumnContainer>

                <Divider />

                <InterconnectApplicantButtons step={InterconnectApplicantStep.ConnectionInfo}
                                              {...props}
                                              nextDisabled={submitDisabled}
                                              onNext={onNext}
                />
            </Form>
        </FormProvider>
    )

}