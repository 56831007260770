import React, { useState} from "react";
import {ApplicationForm, BaseFormDataProps} from "../application/Application";
import { GridColumnContainerSignature, PageSubTitle} from "../../styledcomponents/MiscStyledComponents";
import axios from "axios";
import GreyBanner from "../misc/GreyBanner";
import {useMessageService} from "../../hooks/useMessageService";
import { usePath} from "../../PathProvider";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAuth} from "../../auth/AuthProvider";
import {ApplicationSummaryContent} from "../application/helpers/ApplicationSummaryContent";
import {HoverSpan, Input, TextArea} from "../form/FormElements";
import {FieldError, FieldErrorsImpl, FormProvider, Merge, SubmitHandler, useFieldArray, useForm, useWatch} from "react-hook-form";
import {FormActionType} from "../application/helpers/applicationFormReducer";
import {Form, Label, SmallPrimaryButton, StyledInput, StyledLinkButton} from "../../styledcomponents/FormStyledComponents";
import ApplicationFormUtils from "../../utils/applicationFormUtils";
import {AxiosError, AxiosResponse} from "axios";
import { canOverwriteAgreement} from "../../enums/ApplicationStatus";
import {UploadedDocumentsTable} from "../application/helpers/UploadedDocumentsTable";
import {ViewContactInformation} from "../application/helpers/ViewContactInformation";
import {ContactUsError} from "../misc/ContactUsError";
import {ApplicationFeesDisplay} from "../application/steps/ApplicationFeesReview";
import {encode} from 'base64-arraybuffer'
import styled from "styled-components";
import Collapsible, { CollapsibleContainer } from "../misc/Collapsible";
import ErrorUtils from "../../utils/errorUtils";
import { JwtStatus, getAgreementContent, getTermsAndConditionsContent, parseJwt } from "../../utils/termsAgreementUtil";
import { getBackendHost } from "../../utils/externalUrl";
import { UploadSpecificDocumentBox } from "../application/helpers/UploadSpecificDocumentBox";
import { AttachmentType } from "../../models/AttachmentResponse";
import ExternalLink from "../misc/ExternalLink";


/** Used for PDF rendering, avoids page breaks inside this element */
const NoPageBreak = styled.div`
    break-inside: avoid;
`

export type SignatureData = BaseFormDataProps & {
    signature ?: string
    title ?: string
    agreementLanguage ?: string
}

export type SignaturesFormData = BaseFormDataProps & {
    signatures : SignatureData[]
}

type SignatureFormProps = {index: number, agreementLanguage : string,  errors ?: Merge<FieldError, (Merge<FieldError, FieldErrorsImpl<SignatureData>> | undefined)[]>}
function SignatureForm(props: SignatureFormProps) {
    const error = props.errors?.[props.index];
    return <>
        <div>
            <Label htmlFor={`signatures.${props.index}.signature`} >Customer Signature</Label>
            <Input name={`signatures.${props.index}.signature`} required minLength={3} maxLength={70} error={error?.signature}/>
        </div>
        <div>
            <Label htmlFor={`signatures.${props.index}.title`} >Title</Label>
            <Input name={`signatures.${props.index}.title`} required minLength={3} maxLength={70} error={error?.title}/>
        </div>
        <div style={{display: 'none'}}>{/* Not shown to the user. I could not find another way to set the agreement language without causing an infinite loop.*/}
            <Label htmlFor={`signatures.${props.index}.agreementLanguage`} >lang</Label>
            <Input name={`signatures.${props.index}.agreementLanguage`} value={props.agreementLanguage} maxLength={9999}/>
        </div>
    </>
    
}

export default function ReviewAndSignAgreement() {
    

    const messageService = useMessageService();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const path = usePath();
    const auth = useAuth();
    const [application, setApplication] = useState<ApplicationForm>();
    const [loading, setLoading] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [alreadySigned, setAlreadySigned] = useState<boolean>(false);
    const [replaceMode, setReplaceMode] = useState<boolean>(false); //has the user opted to submit a replacement 

    const [tokenStatus, setTokenStatus] = useState<JwtStatus>({});
    const [test, setTest] = useState<string>('');


    const methods = useForm<SignaturesFormData>({
        defaultValues : {
            signatures: [{}, {}, {}]
        },
        mode : "onChange"
    });
    const { handleSubmit, getValues, setValue, control, watch, reset, formState: { isSubmitting, isDirty, isValid, errors } } = methods;
    let watchSignatures = useWatch({control, name: 'signatures'});
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: "signatures"
    });
    const jwt = searchParams.get('token');

    // We should only allow replacing the signature if the application is open (there is a server-side check for this as well)
    const openStatus = canOverwriteAgreement(application?.status);

    React.useEffect(() => {
        messageService.clearAll();
        loadApplication();
    }, [jwt]);

    const loadApplication = async function() {
        let status = parseJwt(jwt ?? ''); //preliminary client-side check so we can give expiration message if needed
        setTokenStatus(status);
        if (status?.isValid) {
            setLoading(true);
            const appRequest = axios.request({
                baseURL: getBackendHost(),
                headers: {'Authorization': 'Bearer ' + jwt},
                method: "GET",
                url: `/api/agreement/application`
            });
            
            const signatureRequest = axios.request({
                baseURL: getBackendHost(),
                headers: {'Authorization': 'Bearer ' + jwt},
                method: "GET",
                url: `/api/agreement/signature`
            });
            try {
                const [appResponse, signatureResponse] = await Promise.all([appRequest, signatureRequest]);
                let loadedApplication = {...appResponse.data};
                setApplication(ApplicationFormUtils.buildApplicationFormFromLoadedApplication(loadedApplication));
                setAlreadySigned(signatureResponse?.data?.attachmentsStatus?.length > 0);
            } catch (e) {
                messageService.error(ErrorUtils.UNKNOWN_ERROR)
                setTokenStatus({isBroken: true});
            } finally {
                setLoading(false);
            }
        }
    }

    /* Generate component with signature.*/
    const signatureBlock = function(index: number, agreementLanguage: string ) {
        return <FormProvider {...methods}>
            <GridColumnContainerSignature>
                <SignatureForm index={index} errors={errors.signatures} agreementLanguage={agreementLanguage}/>
                
            </GridColumnContainerSignature>
        </FormProvider>
    }

    const onSubmit: SubmitHandler<SignaturesFormData> = async (data: SignaturesFormData) => {
        messageService.clearAll();
        setSubmitting(true);
        // make call to save current state of application
        const formData = new FormData();
        const currentDateString = (new Date()).toISOString();
        const signaturesWithTimestamp = data.signatures.filter(d => d.signature).map(d => ({...d, timestamp: currentDateString}))
        const file = new File([JSON.stringify(signaturesWithTimestamp, null, 2)], "signatures.txt"); 
        formData.append("files", file);
        await axios.request({
            baseURL: getBackendHost(),
            headers: {'Authorization': 'Bearer ' + jwt},
            method: "POST",
            data: formData,
            url: `/api/agreement/uploadSignature`
        }).then(() => {
            messageService.success("Signature submitted successfully.")
            setReplaceMode(false);
            loadApplication();
        }).catch((err: AxiosError) => {
            console.error(err)
            messageService.error("Error saving signature.");
        }).finally(() => {
            setSubmitting(false);
        })
    }

    const downloadPaAgreement = async function() {
        const response = await axios.request({
            baseURL: getBackendHost(),
            headers: {'Authorization': 'Bearer ' + jwt},
            method: "GET",
            responseType: 'blob',
            url: `/api/agreement/agreement.pdf`
        });
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'agreement.pdf'
        link.click()
    }
    
    const state = application?.account?.region;
    const level = application?.additionalInformation?.applicationLevel ?? "01";
    const agreement = state && getAgreementContent(state, level, signatureBlock);
    const termsAndConditions = state && getTermsAndConditionsContent(state, level);
    let errorContent = null;
    if (tokenStatus.isBroken || tokenStatus.isMissing) {
        errorContent = <>
            <p>Something went wrong. If this keeps happening, please contact the application owner/vendor to resend the agreement email from the application status page.</p>
        </>
    } else if (tokenStatus.isExpired) {
        errorContent = <>
            <p>Looks like this temporary link has expired. Please contact the application owner/vendor to resend the agreement email from the application status page.</p>
        </>
    } else if (alreadySigned && !replaceMode) {
        errorContent = <>
            <p>This application has been signed already.</p>
            {openStatus && <p>If there is a problem with your current signature submission, <StyledLinkButton onClick={() => {setReplaceMode(true)}} >click here to replace it</StyledLinkButton>.</p>}
        </>
    } else if (loading) {
        errorContent = <>
            <p>Loading application...</p>
        </>
    }


    return (
        <React.Fragment>
            <PageSubTitle>Review and Sign Customer Agreement</PageSubTitle>
            {errorContent ? 
                errorContent
            :  
                <>   
                <p>Please expand and review the application summary to ensure it is correct. Then, review and sign the agreement below.</p>             
                <CollapsibleContainer>
                    <Collapsible title={'Application Summary'}>
                        {application ?  <div id='print'>
                        <ApplicationSummaryContent application={application} />
                        {
                            application.contactInformationContainer?.contactInformation &&
                            <NoPageBreak>
                                <div style={{height:"0.5em"}}/>
                                <GreyBanner title={"Contact Information"} marginTop={true}/>
                                <ViewContactInformation contactInformation={application.contactInformationContainer?.contactInformation} />
                            </NoPageBreak>
                        }
                    </div> : <></>}
                    </Collapsible>
                    
                    <Collapsible title={'Customer Signature'} forceOpen={true} preventAutoscroll>
                    {state === 'PA' ?
                    <>
                        <p>
                            Please download the agreement file and sign it digitally.<br/>
                            <StyledLinkButton onClick={downloadPaAgreement}>
                                {"agreement.pdf"}
                            </StyledLinkButton>
                            <br/><br/>
                            When you have completed the agreement, upload the signed copy here.
                            Please ensure that all pre-completion customer signature fields are completed before uploading to avoid delays in the application review process.
                        </p> 
                        <UploadSpecificDocumentBox docType={AttachmentType.TERMS_AND_CONDITIONS} extension={"pdf"} setValid={() => {}} urlJwt={jwt!}/> 
                        </>
                    :
                        <FormProvider {...methods}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                {agreement!}
                                <HoverSpan onHover={() => !isValid && methods.trigger()}>
                                    <SmallPrimaryButton
                                        type={"submit"}
                                        float="left"
                                        disabled={!isValid || submitting}
                                    >
                                        {isSubmitting ? "Submitting..." : "Submit"}
                                    </SmallPrimaryButton>
                                </HoverSpan>
                            </Form>
                        </FormProvider>
                    }
                    
                    </Collapsible>
                    {termsAndConditions && <Collapsible title={'Terms and Conditions'}>
                        <div style={{fontSize: '0.9em'}}>{termsAndConditions}</div>
                    </Collapsible>}
                </CollapsibleContainer>
                </>
            }
            
                    
        </React.Fragment>
    )
}