import React, {Suspense, useRef} from 'react';
import {Link, Navigate, NavLink, Route, Routes, useLocation} from "react-router-dom";
import styled from "styled-components";
import './App.css';
import {AuthProvider, useAuth} from "./auth/AuthProvider";
import {MessageContainer} from "./components/misc/MessageContainer";
import firstEnergyLogo from './FE_Logo.svg'
import {useMessageService} from "./hooks/useMessageService";
import {FlexRowContainer, StyledLink} from "./styledcomponents/MiscStyledComponents";
import {Page, usePath} from "./PathProvider";
import {useIdleTimer} from "react-idle-timer";
import ReviewRegistration from "./components/registration/ReviewRegistration";
import RegistrationSuccess from "./components/registration/RegistrationSuccess";
import ForcedResetPassword from './components/ForcedResetPassword';
import FinalizeRegistrationSuccess from "./components/registration/FinalizeRegistrationSuccess";
import InterconnectApplicantForm from "./components/application/forms/interconnectApplicant/InterconnectApplicantForm";
import { DatePickerGlobalStyles } from './styledcomponents/FormStyledComponents';
import ExternalLink from "./components/misc/ExternalLink";
import GetExternalFeUrl, { URLSUFFIX_CONTACT_US } from './utils/externalUrl';
import ReviewAndSignAgreement from './components/user/ReviewAndSignAgreement';

const Login                    = React.lazy(() => import("./components/Login"));
const NoPageFound              = React.lazy(() => import("./components/NoPageFound"));
const AcknowledgementPage      = React.lazy(() => import("./components/AcknowledgementPage"));
const ManageAccount            = React.lazy(() => import("./components/user/ManageAccount"));
const ForgotCredentials        = React.lazy(() => import("./components/ForgotCredentials"));
const ResetForgotPassword      = React.lazy(() => import("./components/ResetForgotPassword"));
const ApplicationTablePage     = React.lazy(() => import("./components/ApplicationTablePage"));
const RegistrationPage         = React.lazy(() => import("./components/registration/RegistrationPage"));
const Application              = React.lazy(() => import("./components/application/Application"));
const FinalizeRegistrationPage = React.lazy(() => import("./components/registration/FinalizeRegistrationPage"));
const ApplicationSummary       = React.lazy(() => import("./components/application/ApplicationSummary"));
const UpdateProfilePage        = React.lazy(() => import("./components/user/UpdateProfilePage"));
const ReviewAndSignPage        = React.lazy(() => import("./components/user/ReviewAndSignAgreement"));


const MainContentWrapper = styled.div<{isLoggedIn : boolean}>`
    grid-area: wrapper;
    display: grid;
    /* If user is logged in we want to show a side nav */
    grid-template-areas:
      ${props => props.isLoggedIn ? `"main main main"` : `"main"` };
    margin: 40px auto;
    max-width: 944px;
    grid-template-columns: repeat(1, 1fr);
    width: 100%; 
    @media screen and (max-width: 1024px) {
        margin: 40px 1rem;
        width: auto;  
    }
`
const HeaderGroup = styled.header`
    flex-wrap:wrap;
`

const LinksGroup = styled.div`
    padding: 0.7em 1em;
    @media screen and (min-width: 1024px) {
        padding-right: 10em;
    }
`

const LogoContainer = styled(FlexRowContainer)`
    margin-top: .55rem;
    align-self: center;
    gap: 0 !important;
    @media screen and (min-width: 1024px) {
        padding-left: 10em;
    }
    img {
        @media screen and (max-width: 400px) {
            width: 8em;
        }
    }
    
`

const WebsiteHeaderTitle = styled.h1`
    font-style: italic;
    margin-left: 0.7em;
    margin-bottom:0.48em;
    margin-top:0.35em;
    font-weight:700;
    letter-spacing:-.04em;
    color: #634F60;
    @media screen and (max-width: 400px) {
        font-size: 1.4rem;
        margin-bottom:0.59em;
        margin-top:0.48em;
    }
`

const LogoTitleGroup = styled.div`
    display: inline-flex;
`

type NavItemProps = {
    to : string;
    text : string;
}

const SideNavListItem = styled.span<{isActive ?: boolean}>`
    color: #0060a9;
    transition: 0.25s ease-in-out;
    padding: 5px 0;
    cursor: pointer;
    ${props => props.isActive ? 'font-weight: bold;' : ''}
    &:hover {
      color: #000;
      text-decoration: underline;
    }
`
const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    &:hover {
      color: #000;
      text-decoration: underline;
    }
    &:visited {
      color: #0060a9;
    }
`


function NavItem(props : NavItemProps) {
    return (
        <StyledNavLink to={props.to}>
            {({ isActive }) => (
                <SideNavListItem isActive={isActive}>
                    {props.text}
                </SideNavListItem>
            )}
        </StyledNavLink>
    )
}

function App() {
    const auth = useAuth();
    const location = useLocation();
    const messageService = useMessageService();
    const path = usePath();

    const locationRef = useRef(location);

    // If the location changes:
    // 1. Clear all messages
    // 2. If there are success of warning messages to show upon redirect, show them
    React.useEffect(() => {
        // verify the path actually changed
        if (location.pathname !== locationRef.current.pathname) {
            messageService.clearAll();

            const {successMessage, warningMessage} = location.state || {};

            if (successMessage) { messageService.success(successMessage); }
            if (warningMessage) { messageService.warning(warningMessage); }

            locationRef.current = location;
        }
    }, [location]);

    React.useEffect(() => {
        const script = document.createElement('script');
        script.id = 'shape';
        script.src = process.env.REACT_APP_F5_SHAPE_URL!;
        script.async = true;
        if(!auth.user && !document.getElementById(script.id)) {
            document.body.appendChild(script);
        } else if(auth.user) {
            document.getElementById(script.id)?.remove();
        }
      }, [process.env.REACT_APP_F5_SHAPE_URL, auth.user]);

    // log the user out if they're idle
    const onIdle = () => {
        if (auth.user) {
            auth.logout("Your session has timed out.");
        }
    }

    useIdleTimer({
        onIdle,
        timeout: process.env.REACT_APP_IDLE_TIMEOUT_MINUTES ? parseInt(process.env.REACT_APP_IDLE_TIMEOUT_MINUTES)*60000 : 90000
    })
    const contactUs = <ExternalLink href={GetExternalFeUrl(URLSUFFIX_CONTACT_US)}>Contact Us</ExternalLink>;
    const privacy = <ExternalLink href={"https://www.firstenergycorp.com/corporate/privacy-notice.html"}>Privacy Notice</ExternalLink>;

    return (
        <div className="App">
            <DatePickerGlobalStyles/>
            <HeaderGroup>
                <LogoTitleGroup>
                    <LogoContainer>
                        <Link to={path.get(Page.ApplicationList)}>
                                <img src={firstEnergyLogo} style={{ height: 30 }} alt="logo" />
                        </Link>
                    </LogoContainer>
                    <WebsiteHeaderTitle>
                        <Link style={{textDecoration: "none", color: "inherit"}} to={path.get(Page.ApplicationList)}>Interconnection Portal</Link>
                    </WebsiteHeaderTitle>
                </LogoTitleGroup>
                {
                    auth.user &&
                    <LinksGroup>
                        <NavItem to={path.get(Page.ApplicationList)} text={'Applications'} /> 
                            {' '} | {auth.user.isVendor && <><NavItem to={path.get(Page.ManageAccount)} text={'Manage Account'} /></>}
                            {' '} | {contactUs} 
                            {' '} | {privacy}
                            {' '} | <StyledLink onClick={() => auth.logout()}>Logout</StyledLink>
                    </LinksGroup>
                }
                {
                    !auth.user &&
                    <LinksGroup>
                        <NavItem to={path.get(Page.Login)} text={'Log In'} /> 
                        {' '} | {contactUs}
                        {' '} | {privacy}
                    </LinksGroup>
                }
            </HeaderGroup>
            {/*This will display any messages added to the app via the messageService*/}
            <MessageContainer />
            {
                auth.user
                    ?
                    <MainContentWrapper isLoggedIn={true}>
                        
                        <main>
                            <Suspense fallback={<div>Loading...</div>}>
                                <Routes>
                                    {/*Vendor only pages*/}
                                    {
                                        auth.user.isVendor &&
                                        <React.Fragment>

                                        </React.Fragment>
                                    }

                                    {/*Pages available to any user*/}
                                    <Route path={path.get(Page.Login)}               element={<Login />                } />
                                    <Route path={path.get(Page.ManageAccount)}       element={<ManageAccount />        } />
                                    <Route path={path.get(Page.ApplicationList)}     element={<ApplicationTablePage /> } />
                                    <Route path={path.get(Page.Application)}         element={<Application />          } />
                                    <Route path={path.get(Page.UpdateProfile)}       element={<UpdateProfilePage />     } />
                                    <Route path={path.get(Page.FinalizeRegistrationSuccess)}  element={<FinalizeRegistrationSuccess />} />
                                    <Route path={path.get(Page.ReviewAndSignAgreement)}       element={<ReviewAndSignAgreement />} />
                                    <Route path={path.get(Page.ApplicationSummary)}           element={<ApplicationSummary />         } />
                                    <Route path={path.get(Page.Root)}                         element={<Navigate to={path.get(Page.ApplicationList)} />} />
                                    <Route path={'*'}                                         element={<NoPageFound />                } />
                                </Routes>
                            </Suspense>
                        </main>
                    </MainContentWrapper>
                    :
                    <MainContentWrapper isLoggedIn={false}>
                        <main>
                            <Suspense fallback={<div>Loading...</div>}>
                                <Routes>
                                    <Route index path={path.get(Page.Login)}            element={<Login />               } />
                                    <Route path={path.get(Page.ForgotCredentials)}      element={<ForgotCredentials />   } />
                                    <Route path={path.get(Page.ForcedResetPassword)}    element={<ForcedResetPassword /> } />
                                    <Route path={path.get(Page.ResetForgotPassword)}    element={<ResetForgotPassword /> } />
                                    <Route path={path.get(Page.AcknowledgementPage)}    element={<AcknowledgementPage /> } />
                                    <Route path={path.get(Page.Registration)}           element={<RegistrationPage />    } />
                                    <Route path={path.get(Page.ReviewRegistration)}     element={<ReviewRegistration />    } />
                                    <Route path={path.get(Page.RegistrationSuccess)}    element={<RegistrationSuccess />  } />
                                    <Route path={path.get(Page.ReviewAndSignAgreement)}       element={<ReviewAndSignAgreement />} />
                                    <Route path={path.get(Page.FinalizeRegistration)}  element={<FinalizeRegistrationPage />} />
                                    <Route path={path.get(Page.CompleteAccountInfo)}  element={<InterconnectApplicantForm />} />
                                    {
                                        /*
                                            If user is not logged in, and they try to navigate to any url besides login,
                                            then this will redirect them to the login page
                                        */
                                    }
                                    <Route path={'*'} element={<Navigate to={path.get(Page.Login)} />} />
                                </Routes>
                            </Suspense>
                        </main>
                    </MainContentWrapper>
            }
        </div>
    );
}

export default App;
