export enum Pattern {
    Email = `.+@.+\\..+`,
    Password = `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*().,]).{8,20}$`,
    HouseNumber = `^(?=^.{1,10}$)^[0-9]+(\\.[0-9]{1,2})?$`,
    Street = `^[a-zA-Z0-9 \\.\\-]{0,40}$`,
    RuralRoute = `^[a-zA-Z0-9 \\.\\-]{0,40}$`,
    PoBox = `^[a-zA-Z0-9 \\.\\#\\-]{0,8}$`,
    Apartment = `^[a-zA-Z0-9 \\.\\#\\-\\/]{0,10}$`,
    Building = `^[a-zA-Z0-9 \\.\\#\\-\\/]{0,10}$`,
    Floor = `^[a-zA-Z0-9 \\.\\#\\-\\/]{0,10}$`,
    City = `^[a-zA-Z0-9 \\.\\-]{0,50}$`,
    ZipCode = `^[0-9]{5}(-[0-9]{4})?$`,
    Block = `^[a-zA-Z0-9 \\.\\#\\-]{0,8}$`,
    NumbersOnly = `^[0-9]*$`,
    PowerFactor = `^(-?0?\\.(8[5-9]|9([0-9])?)|(1(\\.[0]{1,2})?))$`,
    Number1to999 = `^[1-9]\\d{0,2}$`,
    NumberTrillion = `^[1-9]\\d{0,9}$`,
    Number1to999_99 = `^(?:[1-9]\\d{0,2}|999)(?:\\.\\d{1,2})?`,
    LimitedPrecisionMeasure = `(?=^.{1,10}$)^[0-9]+(\\.[0-9]{1,2})?$`,
    KwPrecisionMeasure = `(?=^.{1,10}$)^[0-9]+(\\.[0-9]{1,4})?$`,
    TotalSourceRating = `(?=^.{1,10}$)^[0-9]+(\\.[0-9]{1,3})?$`,
    AccountNumber = `^[0-9]{12}$`,
    SquareFootage = "^[0-9]{1,5}$",
    Setback = "^[0-9]{1,5}$",
    Any = `^.+$`,
}