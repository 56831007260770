import React, {useEffect, useState} from "react";
import {BaseApplicationStepProps, BaseFormDataProps} from "../Application";
import {Controller, FormProvider, SubmitHandler, useForm, useWatch} from "react-hook-form";
import {Form, InsideCollapsibleHeader, Label, SmallPrimaryButton, StyledDatePicker2} from "../../../styledcomponents/FormStyledComponents";
import {useMessageService} from "../../../hooks/useMessageService";
import {FormActionType} from "../helpers/applicationFormReducer";
import {useAxios} from "../../../AxiosProvider";
import {DropdownItem, ErrorP, FlexRowContainer, GridColumnContainer, defaultDropdownItem, minorGap} from "../../../styledcomponents/MiscStyledComponents";
import {CheckboxInput, HoverSpan, Input, LabelWithTooltip, RadioInput, ServerLoadedSelect, SimpleRadio, TooltipValueInput} from "../../form/FormElements";
import ApplicationFormUtils from "../../../utils/applicationFormUtils";
import {AxiosError, AxiosResponse} from "axios";
import {useAuth} from "../../../auth/AuthProvider";
import {State} from "../../../enums/State";
import {Pattern} from "../../../enums/Pattern";
import GreyBanner from "../../misc/GreyBanner";
import { TAG_BATTERY_ONLY, TAG_SOLAR_PV } from "../../../utils/constants";
import { useEffectDeepCompare } from "../../../hooks/useEffectDeepCompare";



type SourceSystem = {
    energySource : DropdownItem,
    acRatedCapacity : string,
    sourceVoltage : string,
    generationEquipment : DropdownItem,
    generationEquipmentValue : string,
    dcRatedCapacity ?: string,
    generationIntent : DropdownItem,
    subscriberNumber ?: string,
    communitySolar ?: boolean,
    netMeterExcessGenerationCreditsMethod ?: string
    estimatedCommissionDate?: string 
    editEnergyStorage: boolean
    energyStorageOutput: string
    energyStorageOutputIsDc: boolean
    energyStorageCapacity: string
    energyStorageDedicatedInverter : boolean
    energyStorageDedicatedInverterRating: string
}

export type ExistingSystem = {
    hasExistingGeneration : boolean, //does the existing system have generation
    hasExistingEnergyStorage : boolean, //does the existing system have energy storage
    existingAcRatedCapacity : string, //rated kW output of all existing inverters and AC generators (power to grid)
    existingDcRatedCapacity : string, //rated kW output of all existing output of all DC generators (solar panels etc...)
    existingEnergyStorageOutput : string, //rated kW output of all existing batteries/energy storage
    existingEnergyStorageOutputIsDc : boolean, //is storage energy output in AC or DC 
    existingEnergyStorageCapacity : string, //rated kWh capacity of all existing batteries/energy storage
    pjmParticipation ?: DropdownItem,
}



export type SystemFormData = BaseFormDataProps & {
    sourceSystem : SourceSystem,
    existingSystem : ExistingSystem,
}

type SystemFormProps = BaseApplicationStepProps & {
}

export const labelsAndTooltips = {
    existingAcRatedCapacity: {label: "Existing AC Rated Capacity (kW AC)", tooltip: "Sum of pre-existing inverter-based generation and AC generator nameplate ratings. Do not include DC generator ratings or battery integrated inverters used exclusively for energy storage."},
    existingDcRatedCapacity: {label: "Existing DC Source Rated Capacity (kW DC)", tooltip: "Sum of pre-existing DC generator (e.g. solar) nameplate ratings. " 
        + "Do not include inverter ratings or energy storage output. Enter 0 if there are no pre-existing DC generators."},
    existingEnergyStorageOutput: {label: "Existing Energy Storage Output (kW)", tooltip: "Sum of max power output (voltage x max continuous output current) for all pre-existing batteries/ energy storage."},
    existingEnergyStorageOutputType: {label: "Existing Energy Storage Output Type"},
    existingEnergyStorageCapacity: {label: "Existing Energy Storage Capacity (kWh)", tooltip: "Sum of nameplate capacity for all pre-existing batteries/ energy storage."},
    energySource: {label: "Energy Source", tooltip: "The energy source driving the generation of electricity. If there is a combination of sources, i.e. solar and battery, select solar."},
    dcRatedCapacity: {label: "Total DC Source Rated Capacity (kW DC)", tooltip: "Sum of all DC generator nameplate ratings. Do not include inverter ratings or energy storage output."},
    acRatedCapacity: {label: "Total AC Rated Capacity (kW AC)", tooltip: "Sum of all inverter-based generation and AC generator nameplate ratings. Do not include DC generator ratings or battery integrated inverters used exclusively for energy storage."},
    generationEquipment: {label: "Generation Equipment", tooltip: "For combination of inverter and battery, or battery only application, select “Inverter”."},
    generationIntent: {label: "Generation Intent", tooltip: "Describe the purpose of the generation or battery operation."},
    netMeterExcessGenerationCreditsMethod: {label: "Net Meter Excess Generation Credit Method", tooltip: "Twelve-Month Period: Net excess generation will be paid out sometime in April, dependent on the customer's billing cycle, each year. " 
        + " Indefinite Method: Net excess generation will be carried forward, indefinitely. This means the customer will not receive payout for net excess generation until either the customer changes their credit method to the Twelve-Month Period, or the customer closes their account."},
    energyStorageOutput: {label: `Total Energy Storage Output (kW)`, tooltip: "Sum of max power output (voltage x max continuous output current) for all batteries/ energy storage."},
    energyStorageOutputType: {label: "Energy Storage Output Type"},
    energyStorageCapacity: {label: "Total Energy Storage Capacity (kWh)", tooltip: "Sum of nameplate capacity for all batteries/ energy storage."},
    pjmParticipation: {label: 'Participates in PJM ancillary services'},
    energyStorageDedicatedInverter: {label: "Does the Battery Have an Integrated Inverter?", tooltip: "Only applies to battery integrated inverters used exclusively for energy storage with kW AC output rating."},
    energyStorageDedicatedInverterRating: {label: "Battery Integrated Inverter Rating (kW AC)"},

}

/** Should the DC source fields appear? */
export const getShouldShowDcSource = function  (data ?: SystemFormData)  {
    //Show DC source if the energy source is not battery only, and there is an inverter
    return !data?.sourceSystem?.energySource?.tags?.includes(TAG_BATTERY_ONLY) && data?.sourceSystem?.generationEquipment?.identifier === '01';
}

export function SystemForm(props : SystemFormProps) {
    const messageService = useMessageService();
    const axios = useAxios();
    const auth = useAuth();
    const methods = useForm<SystemFormData>({
        defaultValues : {
            sourceSystem : {
                energySource : props.application?.system?.sourceSystem?.energySource ?? defaultDropdownItem,
                acRatedCapacity : props.application?.system?.sourceSystem?.acRatedCapacity ?? '',
                sourceVoltage : props.application?.system?.sourceSystem?.sourceVoltage ?? '',
                generationEquipment: props.application?.system?.sourceSystem?.generationEquipment ?? defaultDropdownItem,
                dcRatedCapacity : props.application?.system?.sourceSystem?.dcRatedCapacity ?? '',
                generationIntent : props.application?.system?.sourceSystem?.generationIntent ?? defaultDropdownItem,
                subscriberNumber : props.application?.system?.sourceSystem?.subscriberNumber ?? '',
                communitySolar : props.application?.system?.sourceSystem?.communitySolar,
                netMeterExcessGenerationCreditsMethod : props.application?.system?.sourceSystem?.netMeterExcessGenerationCreditsMethod,
                estimatedCommissionDate: props.application?.system?.sourceSystem?.estimatedCommissionDate ?? '',
                editEnergyStorage: props.application?.system?.sourceSystem?.editEnergyStorage,
                energyStorageOutputIsDc: props.application?.system?.sourceSystem?.energyStorageOutputIsDc,
                energyStorageOutput: props.application?.system?.sourceSystem?.energyStorageOutput,
                energyStorageCapacity: props.application?.system?.sourceSystem?.energyStorageCapacity,
                energyStorageDedicatedInverter: props.application?.system?.sourceSystem?.energyStorageDedicatedInverter,
                energyStorageDedicatedInverterRating: props.application?.system?.sourceSystem?.energyStorageDedicatedInverterRating,

            },
            existingSystem : {
                hasExistingGeneration : props.application?.system?.existingSystem?.hasExistingGeneration,
                hasExistingEnergyStorage : props.application?.system?.existingSystem?.hasExistingEnergyStorage,
                existingAcRatedCapacity : props.application?.system?.existingSystem?.existingAcRatedCapacity, //rated kW output of all existing inverters and AC generators (power to grid)
                existingDcRatedCapacity : props.application?.system?.existingSystem?.existingDcRatedCapacity, //rated kW output of all existing output of all DC generators (solar panels etc...)
                existingEnergyStorageOutputIsDc : props.application?.system?.existingSystem?.existingEnergyStorageOutputIsDc, //is existing storage output AC or DC?
                existingEnergyStorageOutput : props.application?.system?.existingSystem?.existingEnergyStorageOutput, //rated kW output of all existing batteries/energy storage
                existingEnergyStorageCapacity : props.application?.system?.existingSystem?.existingEnergyStorageCapacity, //rated kWh capacity of all existing batteries/energy storage
                pjmParticipation : props.application?.system?.existingSystem?.pjmParticipation || defaultDropdownItem,
            },

        },
        mode: "onChange"
    });
    const {handleSubmit, getValues, resetField, setValue, control, formState : {isSubmitting, isValid, errors, isDirty}} = methods;
    let watchSystem = useWatch({control : control});
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
    //we need this extra state variable to see if we should wipe the "new total" energy state or not when editEnergyStorage is true 
    const [totalAcAutoPopulated, setTotalAcAutoPopulated] = useState<boolean>(false);
    const [existingAcAutoPopulated, setExistingAcAutoPopulated] = useState<boolean>(false);
    const [energyStorageAutoPopulated, setEnergyStorageAutoPopulated] = useState<boolean>(false);

    const hasExistingGeneration = watchSystem?.existingSystem?.hasExistingGeneration;
    const hasExistingEnergyStorage = watchSystem?.existingSystem?.hasExistingEnergyStorage;
    const editEnergyStorage = watchSystem?.sourceSystem?.editEnergyStorage;
    const hasBatteryDedicatedInverter = watchSystem?.sourceSystem?.energyStorageDedicatedInverter;
    const isBatteryOnly = watchSystem.sourceSystem?.energySource?.tags?.includes(TAG_BATTERY_ONLY);
    const isSolarPv = watchSystem.sourceSystem?.energySource?.tags?.includes(TAG_SOLAR_PV);
    const creditMethodRequired = props.application?.account?.region === State.MD && watchSystem.sourceSystem?.generationIntent?.identifier === '01';
    const shouldShowDcSource = getShouldShowDcSource(props.application?.system);
    //for battery only and solar PV installations, there must be an inverter.
    const shouldForceInverter = isBatteryOnly || isSolarPv;
    const shouldForceBatteryStorage = !hasExistingEnergyStorage && isBatteryOnly;

    // useEffects
    useEffect(() => {
        setSaveDisabled(isSubmitting || !isValid);
    }, [isSubmitting, isValid]);

    // Whenever the user modifies the form fields, we want to wait 500 milliseconds for them to stop typing and then
    // update the system object on the applicationForm.
    // The reason we do this is that when the user hits 'save and exit' we are required to save all the partial form data
    // So we must effectively update as we go in order for the applicationForm to have all the partial form data
    useEffectDeepCompare(() => {
        const debounceTimer = setTimeout(() => {
            props.applicationFormsDispatch(
                {
                    type: FormActionType.UPDATE_SYSTEM,
                    system : {
                        sourceSystem : {
                            ...getValues("sourceSystem"),
                            energySource        : getValues('sourceSystem.energySource') || defaultDropdownItem,
                            generationIntent    : getValues('sourceSystem.generationIntent') || defaultDropdownItem,
                            generationEquipment : getValues('sourceSystem.generationEquipment') || defaultDropdownItem,
                        },
                        existingSystem : {
                            ...getValues('existingSystem'),
                            pjmParticipation    : getValues('existingSystem.pjmParticipation') || defaultDropdownItem,

                        },
                    }
                }
            );
        }, 250);
        return () => clearTimeout(debounceTimer);
    }, [watchSystem]);

    

    //added watch hook for equipment type
    useEffect(() => {
        const generationEquipment = props.application?.system?.sourceSystem?.generationEquipment?.identifier

        const equipment = watchSystem?.sourceSystem?.generationEquipment || null
        if (equipment?.identifier && equipment.identifier.trim() !== "") {
            const identifier = equipment.identifier

            if (generationEquipment !== identifier) {
                props.applicationFormsDispatch({
                    type: FormActionType.UPDATE_EQUIPMENT,
                    equipment: {
                        batteries: [],
                        equipmentDetail: [],
                        inverters: [],
                        synchronousGenerators: [],
                        inductionGenerators: [],
                    },
                })
            }
        }
    }, [props.application?.system?.sourceSystem?.generationEquipment]);

   

    const onSubmit : SubmitHandler<SystemFormData> = async (data : SystemFormData) => {
        // clear any messages once we submit
        messageService.clearAll();
        props.applicationFormsDispatch({type: FormActionType.INCOMPLETE_SYSTEM});
        try {
            const saveSystemData = {...ApplicationFormUtils.buildApplicationFormRequestBody(props.application!, auth.user!)};
            const determineLevelConfig = { params : {
                    state: props.application?.account?.region,
                    inverterSize: ApplicationFormUtils.calculateFeeInverterSize(props.application!, true),
                    generationIntent: props.application?.system?.sourceSystem.generationIntent
                }
            };
            //run saveSystem and determineLevel in parallel. saveSystem returns additonalinfo object, determineLevel returns app level
            const [saveSystemResponse, determineLevelResponse] = await Promise.all([
                    axios?.secureApi.put('/api/application/saveSystem', saveSystemData),
                    axios?.secureApi.get('/api/application/determineLevel', determineLevelConfig)
                ]
            );
            //run saveAdditionalInfo to persist the data from the previous APIs
            const newAdditionalInfo = {...saveSystemResponse!.data,
                applicationLevel: determineLevelResponse!.data!.applicationLevel};
            const saveAddtlInfoBody = {...ApplicationFormUtils.buildApplicationFormRequestBody({...props.application!, additionalInformation : newAdditionalInfo}, auth.user!)};
            const addtlInfoResponse = await axios?.secureApi.put('/api/application/saveAdditionalInformation', saveAddtlInfoBody);
            props.applicationFormsDispatch({type: FormActionType.COMPLETE_SYSTEM});
            props.applicationFormsDispatch({type: FormActionType.ADDITIONAL_INFORMATION, additionalInformation : addtlInfoResponse!.data.additionalInformation});
        } catch(err) {
            console.error(err);
            messageService.error('Error saving System info.')
        }
    };
    
    //invalid/hidden field resets

    useEffect(() => {
        if (!shouldShowDcSource) {
            resetField('sourceSystem.dcRatedCapacity', {defaultValue : ''})
        }
    }, [shouldShowDcSource]);

    useEffect(() => {
        if (!isSolarPv) {
            resetField('sourceSystem.communitySolar', {defaultValue : false})
        }
    }, [isSolarPv]);

    useEffect(() =>{
        if (creditMethodRequired) {
            resetField('sourceSystem.netMeterExcessGenerationCreditsMethod', {defaultValue : 'twelveMonthPeriod'})
        } else {
            resetField('sourceSystem.netMeterExcessGenerationCreditsMethod', {defaultValue : ''})
        }
    }, [creditMethodRequired]);

    useEffect(() => {
        if (!hasExistingGeneration) {
            resetField('existingSystem.existingDcRatedCapacity', {defaultValue : ''})
            resetField('existingSystem.existingAcRatedCapacity', {defaultValue : ''})
            if (!hasExistingEnergyStorage) {
                resetField('existingSystem.pjmParticipation', {defaultValue : ''})
            } else {
                resetField('existingSystem.existingAcRatedCapacity', {defaultValue : '0'})//if we have energy storage only, set existing AC total to 0
                setTotalAcAutoPopulated(true);
            }
        } else if (existingAcAutoPopulated) { //remove automatic '0' when user turns existing generation to yes
            resetField('existingSystem.existingAcRatedCapacity', {defaultValue : ''})
            setExistingAcAutoPopulated(false);
        }
        if (!hasExistingEnergyStorage) {
            resetField('existingSystem.existingEnergyStorageOutput', {defaultValue : ''})
            resetField('existingSystem.existingEnergyStorageCapacity', {defaultValue : ''})
        }
    }, [hasExistingEnergyStorage, hasExistingGeneration]);

    useEffect(() => {
        if (watchSystem.sourceSystem?.generationIntent && watchSystem.sourceSystem?.generationIntent.identifier !== '06') {
            resetField('sourceSystem.subscriberNumber', {defaultValue : ''})
        }
    }, [watchSystem.sourceSystem?.generationIntent]);

    useEffect(() => {
        if (!editEnergyStorage) { //reset to null if there is no energy storage, and also set to existing if there is pre-existing energy storage and the user says "no changes"
            setValue('sourceSystem.energyStorageCapacity',watchSystem.existingSystem?.existingEnergyStorageCapacity ?? '')
            setValue('sourceSystem.energyStorageOutput', watchSystem.existingSystem?.existingEnergyStorageOutput ?? '')
            setValue('sourceSystem.energyStorageOutputIsDc', watchSystem.existingSystem?.existingEnergyStorageOutputIsDc ?? false)
            setEnergyStorageAutoPopulated(true);
        } else if (energyStorageAutoPopulated) { // if the user previously had "no changes" for energy storage, wipe the autofilled values which were copied from existing energy storage
            setValue('sourceSystem.energyStorageCapacity', '');
            setValue('sourceSystem.energyStorageOutput', '');
            setEnergyStorageAutoPopulated(false);
        }
    }, [editEnergyStorage,  watchSystem.existingSystem?.existingEnergyStorageCapacity, 
            watchSystem.existingSystem?.existingEnergyStorageOutput, watchSystem.existingSystem?.existingEnergyStorageOutputIsDc]);

    useEffect(() => { //force ac capacity to 0 for battery only
        if (isBatteryOnly) {
            setValue('sourceSystem.acRatedCapacity', '0');
            setTotalAcAutoPopulated(true);
        } else if (totalAcAutoPopulated) {
            resetField('sourceSystem.acRatedCapacity', {defaultValue : ''}) //remove 0 when battery only is deselected
            setTotalAcAutoPopulated(false);
        }
    }, [isBatteryOnly])

    

    return (
        <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
            <GreyBanner title="Pre-Existing System Information" noMargin/>
                <p>If this application is for an upgrade to an existing system which has already been registered, please answer "Yes" to one or both of the following questions.</p>
                <SimpleRadio label={"Is There Pre-Existing Generation at This Location?"} fieldName={"existingSystem.hasExistingGeneration"}/>
                <SimpleRadio label={"Is There Pre-Existing Energy Storage at This Location?"} fieldName={"existingSystem.hasExistingEnergyStorage"}/>
                {([errors?.existingSystem?.hasExistingGeneration?.type, errors?.existingSystem?.hasExistingEnergyStorage?.type].includes("validate")) 
                    && <ErrorP>Please select Yes or No.</ErrorP>}
                {/*If existing generation is yes, then show these two fields*/}
                {
                    (hasExistingGeneration || hasExistingEnergyStorage) && <>
                    
                    <GridColumnContainer columns={2}>
                            

                        { hasExistingGeneration && 
                            <>
                                <TooltipValueInput name={"existingSystem.existingAcRatedCapacity"} pattern={Pattern.KwPrecisionMeasure} {...labelsAndTooltips.existingAcRatedCapacity} errorParent={errors}/>
                                <TooltipValueInput name={"existingSystem.existingDcRatedCapacity"} pattern={Pattern.KwPrecisionMeasure} {...labelsAndTooltips.existingDcRatedCapacity} errorParent={errors}/>
                            </>
                        }
                    </GridColumnContainer>
                    <GridColumnContainer columns={1}><div>
                            <Label htmlFor={"existingSystem.pjmParticipation.identifier"}>{labelsAndTooltips.pjmParticipation.label}</Label>
                            <ServerLoadedSelect name={"existingSystem.pjmParticipation.identifier"}
                                                url={`/api/dropdown/restricted/pjmParticipation`}
                                                required={true}
                                                error={errors?.existingSystem?.pjmParticipation?.identifier}
                            />
                        </div></GridColumnContainer>
                        {
                            hasExistingEnergyStorage && <GridColumnContainer columns={2} gap={minorGap}>
                                <TooltipValueInput name={"existingSystem.existingEnergyStorageOutput"} pattern={Pattern.KwPrecisionMeasure} {...labelsAndTooltips.existingEnergyStorageOutput} errorParent={errors} isDcFieldName="existingSystem.existingEnergyStorageOutputIsDc"/>
                                <TooltipValueInput name={"existingSystem.existingEnergyStorageCapacity"} pattern={Pattern.KwPrecisionMeasure} {...labelsAndTooltips.existingEnergyStorageCapacity} errorParent={errors}/>
                            </GridColumnContainer>
                        }
                    </>
                }
                
                <GreyBanner noMargin marginTop title="New System Information"/>
                {(hasExistingGeneration || hasExistingEnergyStorage) ? 
                    <p>For the following fields, input the <b>new total</b> for the location (do not subtract the pre-existing capacity values).</p> 
                    : <p>Enter the generation and system information for the installation that this application is for.</p>}
                <GridColumnContainer columns={2}>
                    <div>
                        <LabelWithTooltip for={"sourceSystem.energySource.identifier"} {...labelsAndTooltips.energySource}/>
                        <ServerLoadedSelect name={"sourceSystem.energySource.identifier"}
                                            url={`/api/dropdown/restricted/energySource/${props.application?.account?.region}` }
                                            required={true}
                                            error={errors?.sourceSystem?.energySource?.identifier}
                                            usesTags
                        />
                    </div>
                    <div>
                        <LabelWithTooltip for={"sourceSystem.generationEquipment.identifier"} {...labelsAndTooltips.generationEquipment} />
                        <ServerLoadedSelect name={"sourceSystem.generationEquipment.identifier"}
                                            url={`/api/dropdown/restricted/generationEquipment`}
                                            required={true}
                                            error={errors?.sourceSystem?.generationEquipment?.identifier}
                                            disabled={shouldForceInverter}
                                            forceId={shouldForceInverter ? "01" : undefined}
                        />
                    </div>
                    
                </GridColumnContainer>
                {/*If energy source is Solar, show this field*/}
                {
                    watchSystem.sourceSystem?.energySource?.tags?.includes(TAG_SOLAR_PV) &&
                    <React.Fragment>
                        <Label htmlFor={"sourceSystem.communitySolar"} checkbox={true}>
                            <CheckboxInput name={'sourceSystem.communitySolar'} value={true.toString()} error={errors?.sourceSystem?.communitySolar}/>
                            This is a Community Solar Application (Check Yes if Applicable)
                        </Label>
                    </React.Fragment>
                }

                <GridColumnContainer columns={2} gap={minorGap}>
                <TooltipValueInput name={"sourceSystem.acRatedCapacity"} pattern={Pattern.KwPrecisionMeasure} {...labelsAndTooltips.acRatedCapacity} errorParent={errors} disabled={isBatteryOnly}/>
                    <div>
                        {
                            shouldShowDcSource &&
                            <React.Fragment>
                                <LabelWithTooltip for={"sourceSystem.dcRatedCapacity"} {...labelsAndTooltips.dcRatedCapacity} />
                                <Input name={"sourceSystem.dcRatedCapacity"} required={true} pattern={Pattern.KwPrecisionMeasure} error={errors?.sourceSystem?.dcRatedCapacity}/>
                            </React.Fragment>
                        }
                    </div>
                    <div>
                        <Label htmlFor={"sourceSystem.sourceVoltage"}>Generator/Inverter Output Voltage (Volts)</Label>
                        <Input name={"sourceSystem.sourceVoltage"} required={true} pattern={Pattern.LimitedPrecisionMeasure} error={errors?.sourceSystem?.sourceVoltage}/>
                    </div>
                </GridColumnContainer>
                <GridColumnContainer columns={2}>
                    
                    <div>
                        <LabelWithTooltip for={"sourceSystem.generationIntent.identifier"} {...labelsAndTooltips.generationIntent}/>
                        <ServerLoadedSelect name={"sourceSystem.generationIntent.identifier"}
                                            url={`/api/dropdown/restricted/generationIntent/${props.application?.account?.region}`}
                                            required={true}
                                            error={errors?.sourceSystem?.generationIntent?.identifier}
                        />
                    </div>
                </GridColumnContainer>
                <GridColumnContainer columns={2}>
                    <div>
                        {
                            watchSystem.sourceSystem?.generationIntent?.identifier === '06' &&
                            <React.Fragment>
                                <Label htmlFor={"sourceSystem.subscriberNumber"}>Subscriber Organization Number</Label>
                                <Input name={"sourceSystem.subscriberNumber"} required={true} maxLength={20} error={errors?.sourceSystem?.subscriberNumber}/>
                            </React.Fragment>
                        }
                    </div>
                </GridColumnContainer>
                {
                    watchSystem.sourceSystem?.generationIntent?.identifier  === '01' &&
                    props.application?.account?.region === State.MD &&
                    <div>
                        <LabelWithTooltip for={"sourceSystem.netMeterExcessGenerationCreditsMethod"} {...labelsAndTooltips.netMeterExcessGenerationCreditsMethod}/>
                        <FlexRowContainer>
                            <Label checkbox={true} >
                                <RadioInput name={'sourceSystem.netMeterExcessGenerationCreditsMethod'} value={"twelveMonthPeriod"} />
                                Twelve-Month Period
                            </Label>
                            <Label checkbox={true} >
                                <RadioInput name={'sourceSystem.netMeterExcessGenerationCreditsMethod'} value={"indefiniteMethod"} />
                                Indefinite Method
                            </Label>
                        </FlexRowContainer>
                    </div>
                }
                <SimpleRadio label={hasExistingEnergyStorage ? "Are There Changes To Batteries/Energy Storage For This Application?" : "Is There Energy Storage for This Application?"} fieldName={"sourceSystem.editEnergyStorage"} forceValue={shouldForceBatteryStorage ? true : null}/>
                { 
                    editEnergyStorage && <>
                        { hasExistingEnergyStorage && <p style={{marginTop: "-0.6em", marginBottom: "0.75em"}}>Please input the <b>new total</b> for energy storage, including any pre-existing energy storage which hasn't been removed.</p>}
                        <GridColumnContainer columns={2} gap={minorGap}>    
                        
                            <TooltipValueInput name={"sourceSystem.energyStorageOutput"} pattern={Pattern.KwPrecisionMeasure} {...labelsAndTooltips.energyStorageOutput} errorParent={errors} isDcFieldName="sourceSystem.energyStorageOutputIsDc" />
                            <TooltipValueInput name={"sourceSystem.energyStorageCapacity"} pattern={Pattern.KwPrecisionMeasure} {...labelsAndTooltips.energyStorageCapacity} errorParent={errors} />
                        </GridColumnContainer>
                    </>
                }
                {
                    (editEnergyStorage || hasExistingEnergyStorage) && <>
                        <SimpleRadio {...labelsAndTooltips.energyStorageDedicatedInverter} fieldName={"sourceSystem.energyStorageDedicatedInverter"}/>
                        {hasBatteryDedicatedInverter && 
                            <TooltipValueInput name={"sourceSystem.energyStorageDedicatedInverterRating"} pattern={Pattern.KwPrecisionMeasure} {...labelsAndTooltips.energyStorageDedicatedInverterRating} errorParent={errors} />
                        }
                    </>
                }
                <Label htmlFor={`sourceSystem.estimatedCommissionDate`}>
                    Estimated Completed In-Service Date
                </Label>
                <Controller
                    control={control}
                    rules={{required:true}}
                    render={({field: {onChange, onBlur, value, ref}}) => {
                        return <StyledDatePicker2
                            onChange={
                                (value) => {
                                    onChange(
                                        value!.toLocaleString("en-US", {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit"})
                                    )}
                            }
                            selected={value ? new Date(value) : undefined}
                            minDate={new Date(new Date().getTime() + 86400000)}
                            required={true}
                        />
                    }}
                    name={`sourceSystem.estimatedCommissionDate`}
                />
                {
                    errors?.sourceSystem?.estimatedCommissionDate?.type === "required" &&
                    <ErrorP>Estimated Completed In-Service Date is required.</ErrorP>
                }


                {/* This span captures hover over the button even if it is disabled. When that happens trigger the form validation to show users what they are missing */}
                <HoverSpan onHover={() => saveDisabled && methods.trigger()}>
                    <SmallPrimaryButton type={"submit"} disabled={saveDisabled}  float='left'>
                        {isSubmitting ? 'Saving...' : 'Save'}
                    </SmallPrimaryButton>
                </HoverSpan>
            </Form>
        </FormProvider>
    )
}